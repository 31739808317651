import React from 'react';
import Button from '../../../atoms/buttons/Button';
import Paragraph from '../../../atoms/paragraphs/Paragraph';
import { useHistory } from 'react-router-dom';
import Image from '../../../atoms/images/Image';
import Span from '../../../atoms/spans/Span';
import { MANAGE_PLAN_PATH } from '../../../../constants';
import { buildPath } from '../../../organisms/containers/util/pathHelper';
import Anchor from '../../../atoms/anchors/Anchor';

const NotAllowedLayout = (props) => {
  const history = useHistory();
  return (
    <div id='main-content' className='container --billing-alerts'>
      <div className='container'>
        <div className='d-flex flex-column justify-content-center'>
          <Paragraph className='text-header h1 text-align-center mb-5'>
            {props.intl.messages['billingAlertsUpgradePlan']}
          </Paragraph>
          <div className='text-align-center'>
            <Image
              src={
                'https://invoicexpress-assets.s3.eu-west-1.amazonaws.com/billing-alerts-update-plan.png'
              }
              style={{ width: '40%' }}
            />
          </div>
          <Span
            className='color-gray-500 text-align-center mt-5 mb-5'
            style={{ fontSize: '18px' }}
          >
            {props.intl.messages['billingAlertsUpgradePlanDescription']}
          </Span>
          <div className='d-flex flex-row justify-content-center'>
            <div className='col-4 col-md-2 text-align-center me-3'>
              <Button
                className='button button-outline d-block'
                label={props.intl.messages['goBack']}
                onClick={() => history.goBack()}
              />
            </div>
            <div className='row col-4 col-md-2 text-align-center ms-3'>
              <Anchor
                className='button button-primary d-block'
                href={buildPath(MANAGE_PLAN_PATH)}
              >
                {props.intl.messages['upgradeButton']}
              </Anchor>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotAllowedLayout;
