import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Input from '../../atoms/inputs/Input';
import Icon from '../../atoms/icons/Icon';
import Span from '../../atoms/spans/Span';
import { injectIntl } from 'react-intl';

/**
 * ES6 stateless component
 * @param {object} props - React props object
 */
const InputPassword = (props) => {
  const [inputConfig, setInputConfig] = useState({
    type: 'password',
    showOpenEye: true,
  });

  const {
    className,
    id,
    inputClassName,
    inputValue,
    onChange,
    hasError = false,
    error_type,
    placeholder,
  } = props;

  /**
   * Function to change the input type between text and password
   * And show/hide the eye icon
   */
  const changeType = () => {
    const type = inputConfig.type === 'password' ? 'text' : 'password';
    setInputConfig({
      type: type,
      showOpenEye: type === 'password' ? true : false,
    });
  };

  return (
    <div className={className}>
      <div className='position-relative'>
        <Input
          id={id}
          className={inputClassName}
          type={inputConfig.type}
          value={inputValue || ''}
          onChange={onChange}
          placeholder={placeholder}
          error_type={error_type}
        />

        {inputConfig.showOpenEye && (
          <Span id={`${id}_left_icon`} onClick={changeType}>
            <Icon className={`icon fa fa-eye`} />
          </Span>
        )}

        {!inputConfig.showOpenEye && (
          <Span id={`${id}_right_icon`} onClick={changeType}>
            <Icon className={`icon fa fa-eye-slash`} />
          </Span>
        )}
      </div>

      {hasError && (
        <div className='input-error mt-3' id='input_error'>
          {error_type === 'validation' && (
            <ul>
              {props.intl.messages['passwordNotValid']
                .split('\n')
                .map((item) => (
                  <li key={item}>{item}</li>
                ))}
            </ul>
          )}

          {error_type === 'match' && (
            <>{props.intl.messages['passwordDoNotMatch']}</>
          )}
        </div>
      )}
    </div>
  );
};

InputPassword.propTypes = {
  className: PropTypes.string.isRequired,
  id: PropTypes.string,
  inputClassName: PropTypes.string.isRequired,
  inputValue: PropTypes.string,
  onChange: PropTypes.func,
  controlled: PropTypes.bool,
  placeholder: PropTypes.string,
};

export default injectIntl(InputPassword);
