import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import Span from '../../../../atoms/spans/Span';
import Input from '../../../../atoms/inputs/Input';
import Paragraph from '../../../../atoms/paragraphs/Paragraph';
import SelectWithValues from '../../../../atoms/selects/SelectWithValues';
import Button from '../../../../atoms/buttons/Button';
import CheckBoxLabel from '../../../../molecules/labels/CheckBoxLabel';
import { DEFAULT_PHONE_CONTRY } from '../../../../../constants/defaultValues';
import InputPassword from '../../../../molecules/inputs/InputPassword';
import Anchor from '../../../../atoms/anchors/Anchor';
import { INFO_MY_PROFILE } from '../../../../../constants/url';

export const Form = (props) => {
  const {
    fields,
    onChangeField,
    handleSubmit,
    phoneCountryCodes,
    isSubmitDisabled,
    isPasswordMatching,
    isPasswordValid,
  } = props;

  /**
   * Handler for the final checkbox (marketing agreement) of the form.
   * Simply calls the onChangeField function with the toggled value.
   * @param {boolean} toggled - True if the checkbox is checked, false otherwise.
   */
  const handleFinalMarketingAgreement = (toggled) => {
    onChangeField('accepted_marketing', toggled);
  };

  return (
    <div className='form-container'>
      <div className='column'>
        <Paragraph className='text-header h4'>
          {props.intl.messages['myProfileUserDataTitle']}
        </Paragraph>
        <Span className='color-gray-400'>
          {props.intl.messages['myProfileUserDataSubtitle']}
        </Span>
        <div className='sep-light mt-2' />
      </div>
      <div className='form-row row mt-4'>
        <div className='width-100'>
          <label className='text-label mt-3'>
            {props.intl.messages['name']}*:
          </label>
          <Input
            id='name'
            data-testid='name_input'
            type='text'
            controlled
            value={fields?.name || ''}
            onChange={(e) => onChangeField('name', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12'>
          <label className='text-label mt-3'>
            {props.intl.messages['email']}*:
          </label>
          <Input
            id='email'
            type='text'
            controlled
            value={fields?.email || ''}
            onChange={(e) => onChangeField('email', e.target.value)}
          />
        </div>

        <div className='col-md-6 col-sm-12'>
          <label className='text-label mt-3'>
            {props.intl.messages['telephone']}:
          </label>
          <div className='row'>
            <div className='col-5 pr-0'>
              <SelectWithValues
                id='telephone_codes_select'
                keyName='phone_country_id'
                valueName='phone_code'
                options={phoneCountryCodes}
                value={fields?.phone_country_id || DEFAULT_PHONE_CONTRY}
                onChange={(e) =>
                  onChangeField('phone_country_id', e.target.value)
                }
              />
            </div>
            <div className='col-7'>
              <Input
                id='telephone_number_input'
                type='number'
                placeholder='999999999'
                controlled
                value={fields?.phone || ''}
                onChange={(e) => onChangeField('phone', e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='d-flex widh-100 align-items-center'>
        <div className='d-flex flex-row me-2 align-items-center'>
          <CheckBoxLabel
            id={`ds-marketing-agreement`}
            className='checkbox me-2'
            onChange={(e) => handleFinalMarketingAgreement(e.target.checked)}
            checked={fields.accepted_marketing || false}
          ></CheckBoxLabel>
          <Span className='text-paragraph bold --small'>
            <FormattedMessage
              id={'myProfileMarketingAgreement'}
              key={'client-alert'}
              values={{
                a: (chunks) => (
                  <Anchor
                    className='color-brand-primary'
                    href={INFO_MY_PROFILE}
                    target='_blank'
                    key={'client-alert'}
                    pathKey={'client-alert'}
                  >
                    {chunks}
                  </Anchor>
                ),
              }}
            />
          </Span>
        </div>
        <div className='d-flex align-items-center ms-auto'>
          <Span className='color-gray-400'>
            {props.intl.messages['requiredFields']}*
          </Span>
        </div>
      </div>
      <div className='column mt-5'>
        <Paragraph className='text-header h4'>
          {props.intl.messages['myProfilePasswordTitle']}
        </Paragraph>

        <Span className='color-gray-400'>
          {props.intl.messages['myProfilePasswordSubtitle']}
        </Span>
        <div className='sep-light mt-2' />
      </div>

      <div className='form-row row mt-4'>
        <div className='col-md-4 col-sm-12'>
          <label className='text-label mt-3'>
            {props.intl.messages['myProfilePassword']}:
          </label>

          <InputPassword
            id='actual_password'
            type='password'
            value={fields?.actual_password || ''}
            onChange={(e) => onChangeField('actual_password', e.target.value)}
            placeholder='*********'
            className='input-icon --right'
            inputClassName='d-block'
          />
        </div>

        <div className='col-md-4 col-sm-12'>
          <label className='text-label mt-3'>
            {props.intl.messages['myProfileNewPassword']}:
          </label>
          <InputPassword
            id='password_input'
            type='password'
            value={fields?.password || ''}
            onChange={(e) => onChangeField('password', e.target.value)}
            placeholder='*********'
            className='input-icon --right'
            inputClassName='d-block'
            error_type='validation'
            hasError={!isPasswordValid}
          />
        </div>

        <div className='col-md-4 col-sm-12'>
          <label className='text-label mt-3'>
            {props.intl.messages['myProfileConfirmPassword']}:
          </label>

          <InputPassword
            id='password_confirmation'
            type='password'
            value={fields?.password_confirmation || ''}
            onChange={(e) =>
              onChangeField('password_confirmation', e.target.value)
            }
            placeholder='*********'
            className='input-icon --right'
            inputClassName='d-block'
            error_type='match'
            hasError={!isPasswordMatching}
          />
        </div>

        <div className='col-md-6 col-sm-12  mt-4'></div>

        <div className='col-lg-6 col-xs-12 d-flex justify-content-end mt-4'>
          <Button
            className='button button-primary'
            onClick={handleSubmit}
            disabled={isSubmitDisabled}
          >
            {props.intl.messages['save']}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default injectIntl(Form);
