/**
 * Check if an email is valid
 * @param {String} stringToValidate - string to validate
 * @returns an array of the match results
 */
export const validateEmailFormat = (stringToValidate) => {
  const validator = require('email-validator');
  return validator.validate(stringToValidate);
};

/**
 * Check if two values are equal
 * @param {String} firstString - string to validate
 * @param {String} secondString - string to validate
 * @returns an array of the match results
 */
export const validateEqualValues = (firstString, secondString) => {
  return (firstString === secondString);
};

/**
 * Validate a password against the following rules:
 * - Minimum length of 12 characters
 * - Must contain at least one special character
 * - Must contain at least one capital letter
 * - Must contain at least one number
 * @param {String} stringToValidate - Password to validate
 * @returns {Boolean} True if the password is valid, False otherwise
 */
export const validatePassword = (stringToValidate) => {
    const hasMinLength = stringToValidate.length >= 12;
    const hasSpecialChar = /[!@#$%^&*()_+=[\]{};':"\\|,.<>?]/.test(stringToValidate);
    const hasCapitalLetter = /[A-Z]/.test(stringToValidate);
    const hasNumber = /\d/.test(stringToValidate);

    return hasMinLength && hasSpecialChar && hasCapitalLetter && hasNumber;
};
